.mantine-NumberInput-rightSection, .mantine-TextInput-rightSection {
    width: auto;
}

.mantine-Image-placeholder {
    background: #fff;
    border: 0.0625rem solid #ced4da;
}

@keyframes keyLoadin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.IconLoader2 {
    animation: keyLoadin 1s linear infinite;
}