.markdown pre:first-child, .markdown p:first-child, .markdown ul:first-child, .markdown ol:first-child, .markdown blockquote:first-child {
  margin-top: 0;
}

.markdown pre:last-child, .markdown p:last-child, .markdown ul:last-child, .markdown ol:last-child, .markdown blockquote:last-child {
  margin-bottom: 0;
}

.markdown ul {
  padding-left: 20px;
}

.markdown li {
  padding-left: 4px;
}

.mantine-NumberInput-rightSection, .mantine-TextInput-rightSection {
  width: auto;
}

.mantine-Image-placeholder {
  background: #fff;
  border: .0625rem solid #ced4da;
}

@keyframes keyLoadin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.IconLoader2 {
  animation: 1s linear infinite keyLoadin;
}

/*# sourceMappingURL=index.fe46d8b6.css.map */
